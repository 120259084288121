@import-normalize;

body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --gap: 1rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
    box-sizing: border-box;
}

#root {
    display: flex;
    flex-direction: column;
}

// Hide number spinners in number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
    overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.Modal {
    position: absolute;
    top: 2.5rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 2.5rem;
    width: 80%;
    max-width: 1800px;
    height: calc(100vh - 50px - 40px);
    @media only screen and (max-width: 768px) {
        width: 90%;
    }
    background-color: #fff;
    padding: 1.5rem 0.5rem;
    border: 2px solid #a7b9dc;
    overflow-y: auto;

    &:focus {
        outline: none;
    }
}

.ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.5);
    z-index: 50;
}

form {
    p {
        color: #ff0000;
    }
}

h1 {
    margin-block-end: 7px;
}

a {
    text-decoration: none;
    color: inherit;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.cursor-pointer {
    cursor: pointer;
}

.select-none {
    user-select: none;
}

@media print {
    html, body {
      height: 100vh;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden;
    }
  }
